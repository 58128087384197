import { useQuery } from '@apollo/client';
import { Link, useParams } from 'react-router-dom';
import { gql } from '../__generated__';
import AddEntry from './AddEntry';
import EntryList from './EntryList';

import Breadcrumb from 'react-bootstrap/Breadcrumb';
import EditTrackerModal from './EditTrackerModal';
import { useEffect, useState } from 'react';
import { Entry } from '../__generated__/graphql';
import DeleteEntryModal from './DeleteEntryModal';
import Chart from './Chart';
import Stats from './Stats';

const GET_TRACKER_ENTRIES = gql(`
query GetEntries($id: ID!, $first: Int, $after: String, $last: Int, $before: String) {
    me {
      id
      tracker(id: $id) {
        id
        name
        goal
        entries(first: $first, after: $after, last: $last, before: $before) {
          totalCount
          pageInfo {
            endCursor
            hasNextPage
            hasPreviousPage
            startCursor
          }
          edges {
            node {
              id
              timestamp
              quantity
            }
          }
        }
        chartData {
          currentPeriod {
            label
            data {
              x
              y
            }
          }
          previousPeriod {
            label
            data {
              x
              y
            }
          }
          midPeriod {
            label
            data {
              x
              y
            }
          }
          longPeriod {
            label
            data {
              x
              y
            }
          }
          barChartLabels
          barChartHistoricalData
          barChartCurrentData
        }
      }
    }
  }
  `);

const TrackerPage = (): JSX.Element => {
  const { trackerId } = useParams<string>();
  const { loading, error, data, refetch, variables } = useQuery(
    GET_TRACKER_ENTRIES,
    {
      variables: { id: trackerId as string, first: 10 },
    },
  );
  const [editingEntry, setEditingEntry] = useState<Entry | null>(null);
  const [entryToDelete, setEntryToDelete] = useState<Entry | null>(null);
  const [latestEntry, setLatestEntry] = useState<Entry | null>(null);
  const [proposedNewValue, setProposedNewValue] = useState<number | null>(null);

  useEffect(() => {
    if (
      variables?.first &&
      !variables.after &&
      !variables.last &&
      !variables.before &&
      data &&
      data.me.tracker.entries.edges.length > 0
    )
      setLatestEntry(data?.me.tracker.entries.edges[0].node || null);
  }, [data, variables]);

  const refresh = () => {
    refetch({ first: 10, after: null, last: null, before: null });
  };

  const entryAdded = () => {
    refresh();
  };

  const getNextPage = () => {
    if (data?.me.tracker.entries.pageInfo.hasNextPage) {
      refetch({
        first: 10,
        after: data.me.tracker.entries.pageInfo.endCursor,
        last: null,
        before: null,
      });
    }
  };

  const getPreviousPage = () => {
    if (data?.me.tracker.entries.pageInfo.hasPreviousPage) {
      refetch({
        first: null,
        after: null,
        last: 10,
        before: data.me.tracker.entries.pageInfo.startCursor,
      });
    }
  };

  const doneEditing = (save: boolean) => {
    if (save) refresh();
    setEditingEntry(null);
  };
  const editEntry = (entry: Entry) => {
    setEditingEntry(entry);
  };

  const doneDeleting = (deleted: boolean) => {
    if (deleted) refresh();
    setEntryToDelete(null);
  };

  if (loading) return <h1>Loading...</h1>;
  if (error) return <h1>Error</h1>;
  if (!data) return <h1>No data</h1>;
  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>
          Home
        </Breadcrumb.Item>
        <Breadcrumb.Item active>{data.me.tracker.name}</Breadcrumb.Item>
      </Breadcrumb>
      <AddEntry
        trackerId={data.me.tracker.id}
        entryAdded={entryAdded}
        setProposedNewValue={setProposedNewValue}
      />
      <Stats
        latestEntry={latestEntry}
        chartData={data.me.tracker.chartData}
        proposedNewValue={proposedNewValue}
        goal={data.me.tracker.goal}
      />
      <Chart
        chartData={data.me.tracker.chartData}
        proposedNewValue={proposedNewValue}
      />
      <EntryList
        entries={data.me.tracker.entries}
        getNextPage={getNextPage}
        getPreviousPage={getPreviousPage}
        editEntry={editEntry}
        deleteEntry={setEntryToDelete}
      />
      <EditTrackerModal
        trackerId={data.me.tracker.id}
        doneEditing={doneEditing}
        entry={editingEntry}
      />
      <DeleteEntryModal entry={entryToDelete} doneDeleting={doneDeleting} />
    </>
  );
};

export default TrackerPage;
