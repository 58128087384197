import React from 'react';

import {
  withAuthenticator,
  WithAuthenticatorProps,
} from '@aws-amplify/ui-react';

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import '@aws-amplify/ui-react/styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-datetime/css/react-datetime.css'
import './App.css';
import { Outlet } from 'react-router-dom';

function Home(props?: WithAuthenticatorProps) {
  return (
    <>
      <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
        <Container>
          <Navbar.Brand>Keep Track</Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar" />
          <Navbar.Collapse
            id="responsive-navbar"
            className="justify-content-end"
          >
            <Nav>
              <Navbar.Text>{props?.user?.attributes?.email}</Navbar.Text>
              <Nav.Link onClick={props?.signOut}>Sign Out</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container>
        <Outlet />
      </Container>
    </>
  );
}

function App(props?: WithAuthenticatorProps) {
  return (
    <>
      <Home {...props} />
    </>
  );
}

export default withAuthenticator(App, { loginMechanisms: ['email'] });
