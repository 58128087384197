import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { BsPlusCircle, BsGear, BsCheck, BsTrash } from 'react-icons/bs';

import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ListGroup from 'react-bootstrap/ListGroup';
import Modal from 'react-bootstrap/Modal';

import { gql } from '../__generated__';
import AddTrackerModal from './AddTrackerModal';
import { useMutation, useQuery } from '@apollo/client';
import { BasicTracker } from './types';

const GET_TRACKERS = gql(`
query GetTrackers {
  me {
    id
    trackers {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          id
          name
          goal
          timePeriod
          endOfDayHour
        }
      }
    }
  }
}
`);

const DELETE_TRACKER = gql(`
mutation DeleteTracker($trackerId:String!) {
  deleteTracker(trackerId:$trackerId)
}
`);

const TrackerList = (): JSX.Element => {
  const { loading, error, data, refetch } = useQuery(GET_TRACKERS);
  const [deleteTracker, { loading: deleting, error: deleteError, reset }] =
    useMutation(DELETE_TRACKER);
  const [showAddModal, setShowAddModal] = useState(false);
  const [configMode, setConfigMode] = useState(false);
  const [editTracker, setEditTracker] = useState<BasicTracker | undefined>(
    undefined,
  );
  const [trackerToDelete, setTrackerToDelete] = useState<string | undefined>();
  const navigate = useNavigate();

  const handleShow = () => {
    setShowAddModal(true);
  };
  const handleClose = (trackerSaved: boolean) => {
    if (trackerSaved) refetch();
    setShowAddModal(false);
    setEditTracker(undefined);
  };

  const handleDelete = (trackerId: string) => {
    deleteTracker({ variables: { trackerId } })
      .then(() => refetch())
      .then(() => setTrackerToDelete(undefined));
  };

  const hideDeleteModal = () => {
    setTrackerToDelete(undefined);
  };

  return (
    <>
      <AddTrackerModal
        showModal={showAddModal}
        handleClose={handleClose}
        tracker={editTracker}
      />
      <Modal show={!!trackerToDelete} onHide={hideDeleteModal}>
        <Modal.Header>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this tracker?</p>
          {deleteError ? (
            <p>
              <Alert variant="danger" dismissible onClose={() => reset()}>
                {deleteError.message}
              </Alert>
            </p>
          ) : (
            ''
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={hideDeleteModal} variant="outline-dark">
            Cancel
          </Button>
          <Button
            onClick={() => handleDelete(trackerToDelete as string)}
            variant="danger"
            disabled={deleting}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <h1>
        Trackers{' '}
        {configMode ? (
          <ButtonGroup>
            <Button onClick={handleShow}>
              <BsPlusCircle />
            </Button>
            <Button onClick={() => setConfigMode(false)}>
              <BsCheck />
            </Button>
          </ButtonGroup>
        ) : (
          <Button variant="light" onClick={() => setConfigMode(true)}>
            <BsGear />
          </Button>
        )}
      </h1>
      {loading ? (
        'Loading...'
      ) : error ? (
        'Error'
      ) : !data ? (
        'No data'
      ) : data.me.trackers.edges.length === 0 ? (
        <>
          No trackers yet...
          <Button onClick={handleShow} size="sm">
            Add one
          </Button>
        </>
      ) : (
        <ListGroup>
          {data.me.trackers.edges.map((e) => (
            <ListGroup.Item
              action={!configMode}
              onClick={() => {
                if (!configMode) navigate(`/tracker/${e.node.id}`);
              }}
              key={e.node.id}
              className={`d-flex ${configMode ? 'ps-3 p-0' : ''}`}
            >
              <div className="me-auto align-self-center">{e.node.name}</div>
              <div>
                {configMode ? (
                  <>
                    <Button
                      variant="light"
                      disabled={deleting}
                      onClick={() => {
                        setEditTracker(e.node);
                        handleShow();
                      }}
                    >
                      <BsGear />
                    </Button>
                    <Button
                      variant="danger"
                      disabled={deleting}
                      onClick={() => setTrackerToDelete(e.node.id)}
                    >
                      <BsTrash />
                    </Button>
                  </>
                ) : (
                  ''
                )}
              </div>
            </ListGroup.Item>
          ))}
        </ListGroup>
      )}
    </>
  );
};

export default TrackerList;
