import { useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';

import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

import { BsPlusCircle } from 'react-icons/bs';

import { gql } from '../__generated__';

const ADD_ENTRY = gql(`
mutation AddEntry($entry: EntryInput!) {
    upsertEntry(entry: $entry) {
      id
      timestamp
      quantity
    }
  }  
`);

const AddEntry = (props: {
  trackerId: string;
  entryAdded: () => void;
  setProposedNewValue: (value: number | null) => void;
}): JSX.Element => {
  const [amount, setAmount] = useState<string>('');
  const [addEntry, { loading, error, reset }] = useMutation(ADD_ENTRY);
  const [parseError, setParseError] = useState<string>();

  const clearErrors = () => {
    reset();
    setParseError(undefined);
  };

  const handleSubmit = () => {
    if (!amount) setParseError('Amount must be set');
    else {
      const amt = Number.parseFloat(amount);
      addEntry({
        variables: {
          entry: {
            trackerId: props.trackerId,
            quantity: amt,
            timestamp: new Date().toISOString(),
          },
        },
      }).then(() => {
        setAmount('');
        props.entryAdded();
      });
    }
  };

  useEffect(() => {
    if (amount) {
      const v = Number.parseFloat(amount);
      if (v) {
        props.setProposedNewValue(v);
        return;
      }
    }
    props.setProposedNewValue(null);
  }, [amount]);

  return (
    <Card>
      <Card.Body>
        <InputGroup>
          <InputGroup.Text>Add Amount</InputGroup.Text>
          <Form.Control
            type="number"
            placeholder="Amount"
            aria-label="Amount"
            onChange={(e) => setAmount(e.target.value)}
            value={amount}
          />
          <Button onClick={() => handleSubmit()} disabled={loading}>
            <BsPlusCircle />
          </Button>
        </InputGroup>
        {error || parseError ? (
          <Alert variant="error" dismissible onClose={() => clearErrors()}>
            {error?.message || parseError}
          </Alert>
        ) : (
          ''
        )}
      </Card.Body>
    </Card>
  );
};

export default AddEntry;
