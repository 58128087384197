import React, { useContext, useEffect, useState } from 'react';
import { gql } from '../__generated__';

import Accordion from 'react-bootstrap/Accordion';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

import { useMutation } from '@apollo/client';
import { BasicTracker } from './types';
import { TrackerGoal, TrackerTimePeriod } from '../__generated__/graphql';
import AccordionContext from 'react-bootstrap/AccordionContext';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';

const ADD_TRACKER = gql(`
mutation UpsertTracker ($tracker: TrackerInput!) {
  upsertTracker(tracker: $tracker) {
    id
    name
    goal
    timePeriod
    endOfDayHour
  }
}
`);

const ContextAwareToggle = ({
  children,
  eventKey,
  callback,
}: {
  children: any;
  eventKey: string;
  callback?: any;
}) => {
  const { activeEventKey } = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey),
  );

  const isCurrentEventKey = activeEventKey === eventKey;

  return (
    <p onClick={decoratedOnClick}>
      {children} {isCurrentEventKey ? '▼' : '▶'}
    </p>
  );
};

interface AddTrackerModalProps {
  showModal: boolean;
  handleClose: (trackerSaved: boolean) => void;
  tracker?: BasicTracker;
}

const AddTrackerModal = (props: AddTrackerModalProps): JSX.Element => {
  const [newTrackerName, setNewTrackerName] = useState('');
  const [goal, setGoal] = useState<TrackerGoal | undefined>(undefined);
  const [timePeriod, setTimePeriod] = useState<TrackerTimePeriod | undefined>(
    undefined,
  );
  const [endOfDayHour, setEndOfDayHour] = useState('0');
  const [mutateFunction, { loading, error, reset }] = useMutation(ADD_TRACKER);

  const handleClose = () => {
    props.handleClose(false);
    setNewTrackerName('');
    setEndOfDayHour('0');
  };

  const handleSave = () => {
    const hr = Number.parseInt(endOfDayHour);
    const date = new Date();
    date.setHours(hr);
    
    mutateFunction({
      variables: {
        tracker: {
          name: newTrackerName,
          id: props.tracker?.id,
          goal,
          timePeriod,
          endOfDayHour: date.getUTCHours(),
        },
      },
    }).then(() => {
      props.handleClose(true);
      setNewTrackerName('');
      setEndOfDayHour('0');
    });
  };

  useEffect(() => {
    setNewTrackerName(props.tracker?.name || '');
    setGoal(props.tracker?.goal || undefined);
    setTimePeriod(props.tracker?.timePeriod || undefined);
    setEndOfDayHour(props.tracker?.endOfDayHour?.toString() || '0');
  }, [props.tracker]);

  return (
    <>
      <Modal show={props.showModal} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>
            {props.tracker ? 'Edit Tracker' : 'Add a Tracker'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error ? (
            <Alert variant="danger" dismissible onClose={() => reset()}>
              {error.message}
            </Alert>
          ) : (
            ''
          )}
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              handleSave();
            }}
          >
            <Form.Group>
              <Form.Label>I want to</Form.Label>
              <Form.Select
                value={goal}
                onChange={(e) => setGoal(e.target.value as any)}
              >
                <option>-</option>
                <option value="INCREASE">Increase</option>
                <option value="DECREASE">Decrease</option>
              </Form.Select>
            </Form.Group>
            <Form.Group>
              <Form.Label>how often I</Form.Label>
              <Form.Control
                type="text"
                value={newTrackerName}
                onChange={(e) => setNewTrackerName(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>per</Form.Label>
              <Form.Select
                value={timePeriod}
                onChange={(e) => setTimePeriod(e.target.value as any)}
              >
                <option>-</option>
                <option value="DAILY">Day</option>
                <option value="WEEKLY">Week</option>
              </Form.Select>
            </Form.Group>
            <Accordion>
              <div className="mt-4">
                <ContextAwareToggle eventKey="advanced">
                  Advanced
                </ContextAwareToggle>
              </div>
              <Accordion.Collapse eventKey="advanced">
                <Form.Group>
                  <Form.Label>End of Day Hour</Form.Label>
                  <Form.Control
                    type="number"
                    value={endOfDayHour}
                    onChange={(e) => setEndOfDayHour(e.target.value)}
                  />
                </Form.Group>
              </Accordion.Collapse>
            </Accordion>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button disabled={loading ? true : false} onClick={handleSave}>
            {loading ? 'Saving...' : props.tracker ? 'Save' : 'Add'}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddTrackerModal;
