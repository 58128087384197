import intervalToDuration from 'date-fns/intervalToDuration';
import isBefore from 'date-fns/isBefore';

const dateTimeFormatter = Intl.DateTimeFormat('default', {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
  hour12: true,
});

const timeFormatter = Intl.DateTimeFormat('default', {
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
  hour12: true,
});

export const formatDateTime = (str: string): string => {
  return dateTimeFormatter.format(new Date(str));
};

export const formatTime = (date: Date): string => {
  return timeFormatter.format(date);
};

export const getDurationString = (d: { start: Date; end?: Date }): string => {
  const start = d.start;
  const end = d.end || new Date();

  const duration = intervalToDuration({
    start,
    end,
  });
  const durationParts: string[] = [];

  if (duration.years) durationParts.push(`${duration.years} yr`);
  if (duration.months) durationParts.push(`${duration.months} mo`);
  if (duration.weeks) durationParts.push(`${duration.weeks} wk`);
  if (duration.days) durationParts.push(`${duration.days} d`);

  const hours = `${duration.hours || 0}`.padStart(2, '0');
  const minutes = `${duration.minutes || 0}`.padStart(2, '0');
  const seconds = `${duration.seconds || 0}`.padStart(2, '0');

  durationParts.push(`${hours}:${minutes}:${seconds}`);

  return (isBefore(start, end) ? '' : '-') + durationParts.join(' ');
};
