import { useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';

import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

import DateTime from 'react-datetime';
import { gql } from '../__generated__';

import { Entry } from '../__generated__/graphql';

const UPDATE_ENTRY = gql(`
mutation UpdateEntry($entry: EntryInput!) {
    upsertEntry(entry: $entry) {
      id
      timestamp
      quantity
    }
  }  
`);

interface EditTrackerModalProps {
  entry: Entry | null;
  trackerId: string;
  doneEditing: (save: boolean) => void;
}

const EditTrackerModal = (props: EditTrackerModalProps): JSX.Element => {
  const handleClose = () => props.doneEditing(false);
  const [amount, setAmount] = useState('');
  const [timestamp, setTimestamp] = useState(new Date());
  const [updateEntry, { loading, error, reset }] = useMutation(UPDATE_ENTRY);

  useEffect(() => {
    setAmount(props.entry?.quantity.toString() || '');
    setTimestamp(
      props.entry?.timestamp ? new Date(props.entry.timestamp) : new Date(),
    );
  }, [props.entry]);

  const saveEntry = () => {
    if (props.entry) {
      updateEntry({
        variables: {
          entry: {
            id: props.entry.id,
            quantity: Number.parseFloat(amount),
            timestamp: timestamp.toISOString(),
          },
        },
      }).then(() => props.doneEditing(true));
    }
  };

  return (
    <>
      <Modal show={props.entry != null} onHide={handleClose}>
        <Modal.Header closeButton>Edit Tracker</Modal.Header>
        <Modal.Body>
          {error ? (
            <p>
              <Alert variant="danger" dismissible onClose={reset}>
                {error.message}
              </Alert>
            </p>
          ) : (
            ''
          )}
          <Form.Group>
            <Form.Label>Amount</Form.Label>
            <Form.Control
              type="number"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Timestamp</Form.Label>
            <DateTime
              value={timestamp}
              timeFormat={"HH:mm:ss A"}
              onChange={(v) => {
                if (typeof v === 'string') setTimestamp(new Date(v));
                else setTimestamp(v.toDate());
              }}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button onClick={() => saveEntry()} disabled={loading}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditTrackerModal;
