import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Amplify } from 'aws-amplify';
import { Auth } from 'aws-amplify';
import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import TrackerList from './TrackerList';
import TrackerPage from './TrackerPage';

// import reportWebVitals from './reportWebVitals';

Amplify.configure({
  identityPoolId: 'us-east-1_hrKeESuPY',
  region: 'us-east-1',
  userPoolId: 'us-east-1_hrKeESuPY',
  userPoolWebClientId: '7cutj4lrrhj8mrcu2sv8jbdgkv',
  mandatorySignIn: true,
});

const authLink = setContext(async (_, { headers }) => {
  const session = await Auth.currentSession();
  const token = session.getAccessToken().getJwtToken();
  return {
    headers: { ...headers, authorization: token ? `Bearer ${token}` : '' },
  };
});

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_API_URL || 'http://localhost:3004/graphql',
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      { path: '/', element: <TrackerList /> },
      { path: '/tracker/:trackerId', element: <TrackerPage /> },
    ],
  },
]);

root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <RouterProvider router={router} />
    </ApolloProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
