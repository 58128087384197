import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Pagination from 'react-bootstrap/Pagination';
import Table from 'react-bootstrap/Table';

import { BsPencil, BsTrash } from 'react-icons/bs';
import { formatDateTime } from '../common';

import { Entry, EntryConnection } from '../__generated__/graphql';

const EntryList = (props: {
  entries: EntryConnection;
  getNextPage: () => void;
  getPreviousPage: () => void;
  editEntry: (entry: Entry) => void;
  deleteEntry: (entry: Entry) => void;
}): JSX.Element => {
  return (
    <Card>
      <Card.Body>
        <Card.Title>Entry History</Card.Title>
        {props.entries.totalCount === 0 ? (
          'No Entries'
        ) : (
          <Table size="sm">
            <thead>
              <tr>
                <th>Time</th>
                <th>Quantity</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {props.entries.edges.map((e) => (
                <tr key={e.node.id}>
                  <td>{formatDateTime(e.node.timestamp)}</td>
                  <td>{e.node.quantity}</td>
                  <td>
                    <Button size="sm" onClick={() => props.editEntry(e.node)}>
                      <BsPencil />
                    </Button>
                    <Button
                      size="sm"
                      variant="danger"
                      className="mx-1"
                      onClick={() => props.deleteEntry(e.node)}
                    >
                      <BsTrash />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
        <Pagination>
          <Pagination.Prev
            disabled={!props.entries.pageInfo.hasPreviousPage}
            onClick={() => props.getPreviousPage()}
          />
          <Pagination.Next
            disabled={!props.entries.pageInfo.hasNextPage}
            onClick={() => props.getNextPage()}
          />
        </Pagination>
      </Card.Body>
    </Card>
  );
};

export default EntryList;
