import 'chart.js/auto';
import { ChartDataset } from 'chart.js/auto';
import 'chartjs-adapter-moment';
import { useEffect, useState } from 'react';

import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Card from 'react-bootstrap/Card';

import { Line, Bar } from 'react-chartjs-2';
import { ChartData } from '../__generated__/graphql';
import { BsGraphUp, BsFillBarChartFill } from 'react-icons/bs';

const CURRENT_PERIOD_COLOR = '#ff6385';
const PREVIOUS_PERIOD_COLOR = '#36a2eb';
const PROPOSED_COLOR = '#ffabbd';

interface ChartProps {
  chartData: ChartData;
  proposedNewValue: number | null;
}

const Chart = (props: ChartProps): JSX.Element => {
  const [chartType, setChartType] = useState<'line' | 'bar'>('line');
  const [proposedDataset, setProposedDataset] = useState<
    ChartDataset<'line'>[]
  >([]);
  const [proposedBarData, setProposedBarData] = useState<number[]>([]);
  const datasets: ChartDataset<'line'>[] = [];

  if (props.chartData.currentPeriod)
    datasets.push({
      data: props.chartData.currentPeriod.data,
      label: props.chartData.currentPeriod.label,
      borderColor: CURRENT_PERIOD_COLOR,
      backgroundColor: `${CURRENT_PERIOD_COLOR}80`,
    });

  if (props.chartData.previousPeriod)
    datasets.push({
      data: props.chartData.previousPeriod.data,
      label: props.chartData.previousPeriod.label,
      borderColor: PREVIOUS_PERIOD_COLOR,
      backgroundColor: `${PREVIOUS_PERIOD_COLOR}80`,
    });

  if (props.chartData.midPeriod)
    datasets.push({
      data: props.chartData.midPeriod.data,
      label: props.chartData.midPeriod.label,
      borderColor: '#808080',
      backgroundColor: '#80808080',
      pointRadius: 0,
    });

  if (props.chartData.longPeriod)
    datasets.push({
      data: props.chartData.longPeriod.data,
      label: props.chartData.longPeriod.label,
      borderColor: '#C0C0C0',
      backgroundColor: '#C0C0C080',
      pointRadius: 0,
    });

  const barData = {
    labels: props.chartData.barChartLabels,
    datasets: [
      {
        label: 'past',
        data: props.chartData.barChartHistoricalData,
        backgroundColor: PREVIOUS_PERIOD_COLOR,
      },
      {
        label: 'today',
        data: props.chartData.barChartCurrentData,
        backgroundColor: CURRENT_PERIOD_COLOR,
      },
      {
        label: 'proposed',
        data: proposedBarData,
        backgroundColor: PROPOSED_COLOR,
      },
    ],
  };

  useEffect(() => {
    const calculateProposed = () => {
      if (props.proposedNewValue) {
        const currentPeriodData = props.chartData.currentPeriod?.data || [];
        const currentLatestData =
          currentPeriodData.length > 0
            ? currentPeriodData[currentPeriodData.length - 1]
            : undefined;

        const proposedData = [
          {
            x: new Date().toISOString() as any,
            y: (currentLatestData?.y || 0) + props.proposedNewValue,
          },
        ];

        if (currentLatestData) proposedData.unshift(currentLatestData);

        setProposedDataset([
          {
            data: proposedData,
            borderColor: PROPOSED_COLOR,
            backgroundColor: `${PROPOSED_COLOR}80`,
          },
        ]);
        const barDataCount = props.chartData.barChartLabels.length;
        const proposedBar = new Array(barDataCount).fill(0);
        proposedBar[barDataCount - 1] = props.proposedNewValue;
        setProposedBarData(proposedBar);
      } else {
        setProposedDataset([]);
        setProposedBarData([]);
      }
    };

    calculateProposed();
    const interval = setInterval(calculateProposed, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [props]);

  return (
    <Card>
      <Card.Body>
        <Card.Title className="d-flex flex-row justify-content-between">
          <div>Chart</div>{' '}
          <div>
            <ButtonGroup>
              <Button
                size="sm"
                onClick={() => setChartType('line')}
                variant={chartType === 'line' ? 'secondary' : 'outline-dark'}
              >
                <BsGraphUp />
              </Button>
              <Button
                size="sm"
                onClick={() => setChartType('bar')}
                variant={chartType === 'bar' ? 'secondary' : 'outline-dark'}
              >
                <BsFillBarChartFill />
              </Button>
            </ButtonGroup>
          </div>
        </Card.Title>
        {(() => {
          if (chartType === 'line') {
            return (
              <Line
                data={{ datasets: [...proposedDataset, ...datasets] }}
                options={{
                  plugins: {
                    legend: {
                      position: 'chartArea',
                      labels: { filter: (item) => !!item.text },
                    },
                  },
                  responsive: true,
                  scales: { x: { type: 'time' } },
                }}
              />
            );
          } else if (chartType === 'bar')
            return (
              <Bar
                data={barData}
                options={{
                  responsive: true,
                  scales: { x: { stacked: true }, y: { stacked: true } },
                  plugins: { legend: { display: false } },
                }}
              />
            );
        })()}
      </Card.Body>
    </Card>
  );
};

export default Chart;
