import { useEffect, useState } from 'react';

import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';
import Table from 'react-bootstrap/Table';

import {
  BsCheckLg as CheckIcon,
  BsDashLg as DashIcon,
  BsXLg as XIcon,
} from 'react-icons/bs';

import { formatDateTime, formatTime, getDurationString } from '../common';
import {
  ChartData,
  Entry,
  TimeSeriesDataPoint,
  TrackerGoal,
} from '../__generated__/graphql';

import './Stats.css';

interface StatsProps {
  latestEntry: Entry | null;
  chartData: ChartData;
  proposedNewValue: number | null;
  goal?: TrackerGoal | null;
}

const valueToChartData = (
  value: number,
  chartData: TimeSeriesDataPoint[],
): Date | null => {
  const { upper, lower } = chartData.reduce((a, b) => {
    if (b.y <= value) {
      if (a.lower) {
        if (a.lower.y < b.y) a.lower = b;
      } else {
        a.lower = b;
      }
    }

    if (b.y >= value) {
      if (a.upper) {
        if (a.upper.y > b.y) a.upper = b;
      } else {
        a.upper = b;
      }
    }
    return a;
  }, {} as { upper?: TimeSeriesDataPoint; lower?: TimeSeriesDataPoint });

  if (upper && lower && upper.x === lower.x) return new Date(lower.x);
  else if (upper && lower) {
    const ux = new Date(upper.x).getTime();
    const lx = new Date(lower.x).getTime();
    const m = (lower.y - upper.y) / (lx - ux);
    const b = lower.y - m * lx;
    const x = (value - b) / m;
    return new Date(x);
  } else return null;
};

const TimeDeltaRow = (props: {
  label?: string;
  targetTime?: Date | null;
  timeDelta?: string;
  goal?: TrackerGoal | null;
}): JSX.Element => {
  const isBefore = props.timeDelta?.startsWith('-');
  const goalMet = ((): boolean | undefined => {
    if (!props.goal || !props.timeDelta || isBefore === undefined)
      return undefined;
    else if (props.goal === TrackerGoal.Decrease && isBefore) return false;
    else if (props.goal === TrackerGoal.Increase && !isBefore) return false;
    else return true;
  })();
  if (props.timeDelta && props.targetTime)
    return (
      <tr>
        <td>{props.label}</td>
        <td>{formatTime(props.targetTime)}</td>
        <td>{props.timeDelta}</td>
        <td>
          {goalMet === undefined ? (
            <DashIcon />
          ) : goalMet ? (
            <CheckIcon className="goal-met" />
          ) : (
            <XIcon className="goal-not-met" />
          )}
        </td>
      </tr>
    );
  else return <></>;
};

const Stats = (props: StatsProps): JSX.Element => {
  const [latestDistance, setLatestDistance] = useState<string>('');
  const [previousPeriodDate, setPreviousPeriodDate] = useState<Date | null>(
    null,
  );
  const [midPeriodDate, setMidPeriodDate] = useState<Date | null>(null);
  const [longPeriodDate, setLongPeriodDate] = useState<Date | null>(null);
  const [previousPeriodDelta, setPreviousPeriodDelta] = useState<string>('');
  const [midPeriodDelta, setMidPeriodDelta] = useState<string>('');
  const [longPeriodDelta, setLongPeriodDelta] = useState<string>('');

  useEffect(() => {
    if (props.proposedNewValue) {
      const periodData = props.chartData.currentPeriod?.data;
      const newVal =
        (periodData ? periodData[periodData.length - 1].y : 0) +
        props.proposedNewValue;

      setPreviousPeriodDate(
        valueToChartData(newVal, props.chartData.previousPeriod?.data || []),
      );

      setMidPeriodDate(
        valueToChartData(newVal, props.chartData.midPeriod?.data || []),
      );

      setLongPeriodDate(
        valueToChartData(newVal, props.chartData.longPeriod?.data || []),
      );
    }
  }, [props]);

  useEffect(() => {
    const calcDistance = () => {
      if (props.latestEntry) {
        setLatestDistance(
          getDurationString({
            start: new Date(props.latestEntry.timestamp),
          }),
        );
      }

      setPreviousPeriodDelta(
        previousPeriodDate
          ? getDurationString({ start: previousPeriodDate })
          : '',
      );

      setMidPeriodDelta(
        midPeriodDate ? getDurationString({ start: midPeriodDate }) : '',
      );

      setLongPeriodDelta(
        longPeriodDate ? getDurationString({ start: longPeriodDate }) : '',
      );
    };
    calcDistance();
    const interval = setInterval(calcDistance, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [props, previousPeriodDate, midPeriodDate, longPeriodDate]);

  return (
    <CardGroup>
      <Card>
        <Card.Body>
          <Card.Subtitle>Last Entry</Card.Subtitle>
          <Table size="sm" borderless>
            <tbody>
              {props.latestEntry ? (
                <>
                  <tr>
                    <td>
                      {props.latestEntry.quantity} @{' '}
                      {formatDateTime(props.latestEntry.timestamp)}
                    </td>
                  </tr>
                  <tr>
                    <td>{latestDistance} ago</td>
                  </tr>
                </>
              ) : (
                <tr>
                  <td>None</td>
                </tr>
              )}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>
          <Card.Subtitle>
            Time Deltas{props.goal ? ` (Goal: ${props.goal})` : ''}
          </Card.Subtitle>
          <Table size="sm" borderless id="time-deltas">
            <tbody>
              {props.proposedNewValue ? (
                <>
                  <TimeDeltaRow
                    label={props.chartData.previousPeriod?.label}
                    targetTime={previousPeriodDate}
                    timeDelta={previousPeriodDelta}
                    goal={props.goal}
                  />
                  <TimeDeltaRow
                    label={props.chartData.midPeriod?.label}
                    targetTime={midPeriodDate}
                    timeDelta={midPeriodDelta}
                    goal={props.goal}
                  />
                  <TimeDeltaRow
                    label={props.chartData.longPeriod?.label}
                    targetTime={longPeriodDate}
                    timeDelta={longPeriodDelta}
                    goal={props.goal}
                  />
                </>
              ) : (
                <tr>
                  <td>None</td>
                </tr>
              )}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </CardGroup>
  );
};

export default Stats;
