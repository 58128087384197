import { useMutation } from '@apollo/client';

import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import { gql } from '../__generated__';
import { Entry } from '../__generated__/graphql';

const DELETE_ENTRY = gql(`
mutation DeleteEntry($entryId: String!) {
    deleteEntry(entryId: $entryId)
}`);

interface DeleteModalProps {
  entry: Entry | null;
  doneDeleting: (deleted: boolean) => void;
}

const DeleteEntryModal = (props: DeleteModalProps): JSX.Element => {
  const [deleteEntry, { loading, error, reset }] = useMutation(DELETE_ENTRY);

  const handleDelete = () => {
    if (props.entry)
      deleteEntry({ variables: { entryId: props.entry.id } }).then(() => {
        props.doneDeleting(true);
      });
  };

  return (
    <Modal show={!!props.entry} onHide={() => props.doneDeleting(false)}>
      <Modal.Header>Confirm Delete</Modal.Header>
      <Modal.Body>
        <p>Are you sure you want to delete this entry?</p>
        {error ? (
          <p>
            <Alert variant="danger" dismissible onClose={() => reset()}>
              {error.message}
            </Alert>
          </p>
        ) : (
          ''
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outline-dark"
          onClick={() => props.doneDeleting(false)}
        >
          Cancel
        </Button>
        <Button
          variant="danger"
          onClick={() => handleDelete()}
          disabled={loading}
        >
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteEntryModal;
